<template>
  <div class="box">
    <img src="@/img/gzh.jpg" class="bg" />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.box {
  background: #f5f5f5;
  min-height: calc(100vh - 50px);
  padding-top: 50px;
  text-align: center;
  .bg {
    width: 100%;
  }
}
</style>
